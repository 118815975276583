import React from 'react'
import { Link } from 'react-router-dom'

const WhyAlzea = () => {
  return (
    <div className="innerPageWrapper">
    <div className="page-banner" style={{
      backgroundImage:`url('/images/whyalzea.jpg')`,
      backgroundRepeat:'no-repeat',
      backgroundSize:'cover',
      minHeight:'397px',
      display:'flex',
      alignItems:'center'
      }}>
        <div className='patchContainer'>
          <div className='whyAlzContent'>
          <h1>WHY CHOOSE US?</h1>
           <p>ALZEA INDIA boasts a proven track record, having successfully facilitated over 1200 student journeys in France, offering invaluable exposure. Our commitment to excellence ensures the highest quality internships, and our strong industry connections offer internship opportunities exclusively in groups like Relais & Chateau, Best Western, Michelin Star restaurants and fine dining gastronomic establishments. We take a personalized approach, tailoring internships to align with each student's unique aspirations and career goals.</p>
          </div>
        </div>
    </div>
  
  <div className="page-content pt-50 pb-50">
    <div className='services'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3'>
              <div className='service-items'>
                   <img src='/images/user2.png' alt=''/>
                    <h3>FLUENTZEA</h3>
                    <p>FLUENElevate your French language proficiency with ALZEA INDIA's tailored courses, ensuring effective communication and seamless adaptation to French culture during your journey to France.TZEA</p>
                    <Link to="/" className='borderButton'>Learn More</Link>
              </div>
          </div>
          <div className='col-lg-3'>
              <div className='service-items'>
                   <img src='/images/fitinsideout.png' alt=''/>
                    <h3>FITINSIDEOUT</h3>
                    <p>Our collaboration with FitInsideOut empowers students with a holistic program to tackle lifestyle challenges during their study in France. Enhance physical health, mental strength, and well-being for peak performance.</p>
                    <Link to="/" className='borderButton'>Learn More</Link>
              </div>
          </div>
          <div className='col-lg-3'>
              <div className='service-items active'>
                   <img src='/images/aca.png' alt=''/>
                    <h3>GASTRONOMY ACADEMY</h3>
                    <p>ALZEA's exclusive interviews with Michelin-starred and esteemed hotel chefs offer cultural immersion and valuable insights. Delve into French culinary traditions, signature recipes, and the expectations of the French for a deeper understanding.</p>
                    <Link to="/" className='borderButton'>Learn More</Link>
              </div>
          </div>
          <div className='col-lg-3'>
              <div className='service-items'>
                   <img src='/images/theripy.png' alt=''/>
                    <h3>THERAPY</h3>
                    <p>We offer unwavering emotional support to our interns, with dedicated mentors just a call away. Our nurturing environment ensures every student feels understood. For interns in France, our dedicated on-site team is always available to assist. Our commitment is to ensure a positive and enriching experience for all our students.</p>
                    <Link to="/" className='borderButton'>Learn More</Link>
              </div>
          </div>
        </div>
     </div>
    </div>
    <div className='our-goal'>
      <div className='container text-center'>
      <h4>Our Goal is Simple</h4>
        <p>To empower students and professionals by providing them with exceptional opportunities in France. We aim to inspire and support individuals in defining and achieving success on their own terms. By fostering connections with renowned establishments, we ensure that students receive high-quality experiences that enhance their personal and professional growth.</p>
      </div>
       
    </div>
   </div>
</div>
  )
}

export default WhyAlzea