import React, { Fragment, useState, useEffect } from 'react';
import { GetPosts } from '../../helpers';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
function Single() {
  const [isLoading, setLoading] = useState(true);
  const [post,setPost] = useState(null);
  const [latestPosts,setLatestPosts] = useState([]);
  const {slug} = useParams();

  const getData = async(slug)=>{
    const resp = await GetPosts.getSinglePost(slug);
    if(resp && resp.status){
      setPost(resp.post);
      setLatestPosts(resp.latestPosts);
      setLoading(false);
    }
  }
  
 useEffect(()=>{
  getData(slug)
 }, [slug])

  return (
    <Fragment>
       {
        !isLoading && <div className='innerPageWrapper'>
        <div className='innerPageBanner'>
          <div className='innerDesignEle'>
            <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
              <defs>
                <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#D03D6B" />
                  <stop offset="0.547917" stop-color="#B0519A" />
                  <stop offset="1" stop-color="#5C65AE" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          {/* <div className='container'>
            <h1>Blog</h1>
          </div> */}
        </div>
        <div className='page-content pt-40 pb-50'>
          <div className='container '>
            <div className='row'>
              <div className='col-md-9 col-sm-9'>
                <div className='blog-details'>
                  <div className='blog-featured-image'>
                    <img src={post.thumbnail} alt='img-featured' />
                    <div className="author_details">
                      <h6>{post.auther_name}</h6>
                      <p>{post.post_date}</p>
                    </div>
                  </div>
                  <div className='blog-content'>
                    <h1 className='post-title'>{post.title}</h1>
                      {
                        parse(post.post_details)
                      }
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-3'>
                <ul className='latest-post'>
                  {
                    latestPosts.length !== 0 && latestPosts.map((item, index)=>{
                      return <li key={item.id}>
                      <a href={`/blog/${item.slug}`}>
                        <div className='blogItems'>
                          <div className='blogThumb'>
                            <img src={item.thumbnail} alt='blogThumb' />
                            <div className='author_details'>
                              <h6>{item.auther_name}</h6>
                              <p>{item.post_date}</p>
                            </div>
                          </div>
  
                          <div className='blogTitle'>
                            <h3>{item.title}</h3>
                          </div>
                        </div>
                      </a>
                    </li>
                    })
                  }
                  

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
       }
      
    </Fragment>
  )
}

export default Single