import React, {Fragment} from 'react'
const OurServices = () => {
  return (
    <Fragment>
    <div className='innerPageWrapper'>

        <div className='innerPageBanner'>
            <div className='innerDesignEle'>
                <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
                    <defs>
                        <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D03D6B" />
                            <stop offset="0.547917" stop-color="#B0519A" />
                            <stop offset="1" stop-color="#5C65AE" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className='container'>
                <h1>Our Services</h1>
                <div className='bannerImg'>
                    <img src='/images/otherservicesbnr.jpg' alt='about-banner' className='img-fluid' />
                </div>
            </div>
        </div>
        <div className='page-content pt-50 pb-50'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <a href='/'>
                        <div className='serviceItems'>
                          <img src='/images/addSer1.png' alt='serviceImage' className='img-fluid'/>
                          <div className='serviceOverlay'></div>
                          <div className='serviceItemsContent'>
                          <h3>Mock Interview</h3>
                          </div>
                         
                        </div>
                        </a>
                        
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <a href='/'>
                        <div className='serviceItems'>
                            <img src='/images/ads.png' alt='serviceImage' className='img-fluid'/>
                            <div className='serviceOverlay'></div>
                            <div className='serviceItemsContent'>
                          <h3>CV Building</h3>
                          </div>
                        </div>
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <a href='/'>
                        <div className='serviceItems'>
                            <img src='/images/addSer2.png' alt='serviceImage' className='img-fluid'/>
                            <div className='serviceOverlay'></div>
                            <div className='serviceItemsContent'>
                          <h3>Certified Language Courses</h3>
                          <p>(CTA - Enroll Now, more details - external link of our French website / landing page).</p>
                          </div>
                        </div>
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                    <a href='/wildfit-health-program'>
                        <div className='serviceItems'>
                            <img src='/images/addSer3.png' alt='serviceImage' className='img-fluid'/>
                            <div className='serviceOverlay'></div>
                            <div className='serviceItemsContent'>
                          <h3>Wildfit Health Program</h3>
                          <p>(CTA - Enroll Now, more details - external link of our Wildfit course website / landing page).</p>
                          </div>
                        </div>
                        </a>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</Fragment>
  )
}

export default OurServices