import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
const BrandLogos = ({title}) => {
    
  return (
    <div className='brand-section'>
 <div className='container'>
           <h4>{title}</h4>
           <div className='row'>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                <div className='col-md-2'>
                  <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                </div>
                
           </div>
            {/* <Carousel
                  responsive={responsive}
                  showDots={false}
                  ssr={true}
                  arrows={true}
                  partialVisible={false}
                  itemClass="carousel-item-mr-40-px"
                  removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                >
             
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     <div className='logo-items'>
                         <img src='/images/logo.png' alt='logo'/>
                     </div>
                     
                   
                </Carousel> */}
    </div>
    </div>
   
  )
}

export default BrandLogos