import React,{Fragment, useEffect, useState} from 'react'
import { GetPosts } from '../../helpers';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns'
const Blog = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  // const [page, setPage] = useState(1);

   const getData = async ()=>{
    const resp = await GetPosts.getAllPost();
    if(resp && resp.status){
      setData(resp.posts);
      setLoading(false);
    } 
    // setData((prev) => [...prev, ...resp]);
  
   }
   useEffect(()=>{
    getData();
   },[])
  //  useEffect(()=>{
  //   getData();
  //  },[page])

  //  const handelInfiniteScroll = async () => {
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       console.log(data.length);
  //       if(data.length < 50){
  //           setLoading(true);
  //           setPage((prev) => prev + 1);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handelInfiniteScroll);
  //   return () => window.removeEventListener("scroll", handelInfiniteScroll);
  // }, []);

 
  return (
    <Fragment>

    <div className='innerPageWrapper'>

        <div className='innerPageBanner'>
            <div className='innerDesignEle'>
                <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
                    <defs>
                        <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D03D6B" />
                            <stop offset="0.547917" stop-color="#B0519A" />
                            <stop offset="1" stop-color="#5C65AE" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className='container'>
                <h1>Blog</h1>
                <div className='bannerImg'>
                    <img src='/images/about-page.jpg' alt='about-banner' className='img-fluid' />
                </div>
            </div>
        </div>
        <div className='page-content pt-50 pb-50'>
            <div className='container'>
             
              {
                isLoading ? <div className="fakeLoader" ><div className="lod-1"><div className="lod-2"></div></div></div> :  <div className='row'>
                {
                    data.map((item, index)=>{
                        return   <div className='col-lg-4 col-md-4' key={index}>
                          <Link to={`/blog/${item.slug}`}>
                          <div className='blogItems'>
                            <div className='blogThumb'>
                                <img src={item.thumbnail} alt='blogThumb'/>
                             <div className='author_details'>
                              <h6>{item.auther_name}</h6>
                              <p>{ format (parseISO(item.post_date), 'd MMM Y') }</p>
                            </div>
                            </div>
                          
                            <div className='blogTitle'>
                                <h3>{item.title}</h3>
                            </div>
                        </div>
                          </Link>
                    </div>
                    })
                }
              

              </div>
              }

            </div>
        </div>
    </div>
</Fragment>
  )
}

export default Blog