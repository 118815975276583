import React, { Fragment, useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { motion } from "framer-motion"
import SwipeSlider from '../components/SwipeSlider';
import Accordions from '../components/Accordions';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GetFaqs, GetTestimonial, GetPages, GetWidgets, GetPosts } from '../helpers';
import { format, parseISO } from 'date-fns'
//home components
import MainBanner from '../components/homeComponents/MainBanner';
import VideoTestimonial from '../components/homeComponents/VideoTestimonial';

import { Link } from 'react-router-dom';
import parse from 'html-react-parser';



function Home() {
    // const [isLoading, setLoading] = useState(true);
    const [faqs, setFaqs] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [latestPosts, setLatestPosts] = useState([]);
    const [aboutContent, setAboutContent] = useState(null);
    const [homeBanner, setHomeBanner] = useState(null);

    const getData = async()=>{
        try {
        let [res1, res2, res3, res4, res5] = await Promise.all([
            GetFaqs.getLatestFaqs(),
            GetTestimonial.getLatestTestimonial(),
            GetPages.getAboutUs(),
            GetWidgets.getHomeBannerWidgets(),
            GetPosts.getLatestPost()
            
          ]);
          if(res1 && res1.status){
            setFaqs(res1.faqs)
          }
    
          if(res2 && res2.status){
            setTestimonials(res2.testimonials)
           }

           if(res3 && res3.status){
            setAboutContent(res3.aboutContent)
           }
           if(res4 && res4.status){
            setHomeBanner(res4.homeBanner)
           }
           if(res5 && res5.status){
            setLatestPosts(res5.posts)
           }
          
        }
        catch(err) {
          console.log(err);
        };
    }

    useEffect(()=>{
         getData();
    },[])


    return (
        <Fragment>
 
            <MainBanner homeBanner={homeBanner} />
            <div className='designElement'>
                    <div className='designEle'>
                        <svg width="478" height="188" viewBox="0 0 478 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-6 4C18.9796 37.3181 95.6736 105.586 202.613 112.114C336.288 120.274 398.061 103.444 473 183" stroke="url(#paint0_linear_58_2348)" strokeOpacity="0.8" strokeWidth="13" />
                            <defs>
                                <linearGradient id="paint0_linear_58_2348" x1="-6" y1="4" x2="388.021" y2="341.623" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#D03D6B" />
                                    <stop offset="0.547917" stopColor="#B0519A" />
                                    <stop offset="1" stopColor="#5C65AE" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            <div className='ourLanguages'>
                <div className='container'>
                    <h1>BUILD AN INTERNATIONAL CAREER, ONE INTERNSHIP AT A TIME</h1>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='programText'>
                                <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>6 Months Program</h3>
                                </div>
                               
                                <p>Embark on a transformative culinary journey with ALZEA INDIA's France 6 Months Program. Intern with prestigious hotels and Michelin Star restaurants, receiving a monthly stipend and accommodation. Join now to boost your career in Hotel Management and Culinary with France's gastronomic excellence. </p>
                                <Link className="borderButton style-2" to='/french-language'>Learn more</Link>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                        <div className='programText'>
                        <div className='program-text-header'>
                                <img src='/images/educational-programs.png' alt='program'/>
                                <h3>1 Year Program</h3>
                                </div>
                           
                                <p> ALZEA INDIA presents an extraordinary opportunity for Hotel Management and Culinary students with our France 1 Year Program. France offers an exceptional environment to propel your career with job placements in Michelin Star restaurants, a monthly stipend, and support from the ‘Ministère de l'Intérieur’. Kickstart your hotel industry career with ALZEA INDIA.     </p>
                                <Link className="borderButton style-2" to='/french-language'>Learn more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
            {
                aboutContent !== null &&   <div className='aboutSection'>
          
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-5 pfc'>
                            <motion.img
                                whileHover={{ scale: 1.1, }}
                                whileTap={{ scale: 1 }}
                                initial={{ scale: 1, transitionDelay: 2, }}
                                src={aboutContent.small_image} alt='about' className='img-fluid' />
                        </div>
                        <div className='col-md-7'>
                            <div className='aboutContent'>
                                <h1>{aboutContent.page_title}</h1>
                                {parse(aboutContent.short_description)}
                                <Link to={aboutContent.read_more_link} className='primaryButton'>{aboutContent.read_more_label}</Link>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
            }
            
          
            <div className='ourPortfolio'>
                <div className='container'>
                    <h1>Our portfolio of services include :</h1>
                    <div className='portfolioIcons '>
                        <div className='portfolioIconBox pf-1'>
                            <div className='iconWrapper'>
                                <img src="/images/prtIcon.png" alt="" />
                            </div>
                            <p>Hotel management/ culinary internships & complete logistics in France, Spain, Mauritius and Thailand.</p>
                        </div>
                        <div className='portfolioIconBox pf-2'>
                            <div className='iconWrapper'>
                                <img src="/images/prtIcon.png" alt="" />
                            </div>
                            <p>Internships and
                                complete logistics in
                                Mauritius and India
                                in all domains.</p>
                        </div>
                        <div className='portfolioIconBox pf-3'>
                            <div className='iconWrapper'>
                                <img src="/images/prtIcon.png" alt="" />
                            </div>
                            <p>Study strips in France,
                                Mauritius and India for
                                schools, colleges and universities
                                (including Engineering/
                                Management colleges).</p>
                        </div>
                        <div className='portfolioIconBox pf-4'>
                            <div className='iconWrapper'>
                                <img src="/images/prtIcon.png" alt="" />
                            </div>
                            <p>Language courses
                                (complementary to
                                all our education
                                programs).</p>
                        </div>
                    </div>

                </div>


            </div>
            {/* <div className='ourLearnings'>
                <div className='container'>
                    <h1>Learning / Study Trips</h1>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l1.jpg' alt='li.jpg' />
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map-pin'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                   
                                    <Link to={'#'} className='primaryButton'>Trip Details</Link>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l2.jpg' alt='l2'/>
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                  
                                     <Link to={'#'} className='primaryButton'>Trip Details</Link>


                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l3.jpg' alt='l3'/>
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                  
                                     <Link to={'#'} className='primaryButton'>Trip Details</Link>


                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='learningItems'>
                                <div className='learningItemsThumb'>
                                    <img src='/images/l4.jpg' alt='l4'/>
                                    <div className='blur'></div>
                                </div>
                                <div className='learningItemsContent'>
                                    <h3>4 days & 5 nights</h3>
                                    <div className='location'>
                                        <img src='/images/map-pin.png' alt='map'/>
                                        <p>Lyon & Pays Beaujolais</p>
                                    </div>
                                    <p>Wine, gastronomy and
                                        cultural discoveries in Lyon
                                        & Pays Beaujolais (France)</p>
                                   
                                     <Link to={'#'} className='primaryButton'>Trip Details</Link>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div> */}
            {/* <div className='ourLanguages'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='languageText'>
                                <h3>French language</h3>
                                <p>Bonne Appetit! Hors d’œuvre, garde manger, gastronomie, pâtisserie entrée, à la carte. . . Sounds familiar?</p>
                                <p>You use these and many more French terms in your curriculum. Here’s a chance to learn more and learn it correctly!</p>
                                <p>French is the second most spoken language after English in the world. It is the most important language used in </p>
                                <p>The hotel management / gastronomy industry. Thus, it is essential for hotel management students </p>
                                <Link className="borderButton" to='/french-language'>Read more</Link>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='languageImage'>
                                <motion.img
                                    whileHover={{ scale: 1.1, }}
                                    whileTap={{ scale: 1 }}
                                    initial={{ scale: 1 }}
                                    className="img-fluid"
                                    src='/images/lang1.jpg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                      
            {/* <div className='designEle2'>
                <svg width="416" height="200" viewBox="0 0 416 251" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M415.149 7.55664C373.669 3.8937 271.636 15.3749 195.351 90.6035C99.9954 184.639 71.2606 241.854 -38.0003 244.49" stroke="url(#paint0_linear_58_2301)" strokeOpacity="0.8" strokeWidth="13" />
                    <defs>
                        <linearGradient id="paint0_linear_58_2301" x1="415.149" y1="7.55664" x2="-99.295" y2="75.2983" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#D03D6B" />
                            <stop offset="0.547917" stopColor="#B0519A" />
                            <stop offset="1" stopColor="#5C65AE" />
                        </linearGradient>
                    </defs>
                </svg>
            </div> */}
            {/* <div className='ourLanguages2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='languageImage'>
                                <motion.img
                                    whileHover={{ scale: 1.1, }}
                                    whileTap={{ scale: 1 }}
                                    initial={{ scale: 1 }}
                                    className="img-fluid"
                                    src='/images/lang2.jpg' />
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='languageText'>
                                <h4>Advantages of learning French :</h4>
                                <ul>
                                    <li>It helps in better communication with colleagues and the locals when in France.</li>
                                    <li>There are many francophone countries in the world, where French can be used other than France.</li>
                                    <li>Learning a new language is an add-on in the CV, especially for hotel management students.</li>
                                    <li>A language (French) can be learnt at any age, by anyone, for any purpose, not just if one </li>
                                </ul>
                                <p>  has to go to France. It is an overall growth in oneself, one must just      </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
                {/* <div className='ourLanguages2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='languageImage'>
                                <motion.img
                                    whileHover={{ scale: 1.1, }}
                                    whileTap={{ scale: 1 }}
                                    initial={{ scale: 1 }}
                                    className="img-fluid"
                                    src='/images/lang2.jpg' />
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='languageText'>
                                <h4>1 Year Program :</h4>
                                <p> ALZEA INDIA presents an extraordinary opportunity for Hotel Management and Culinary students with our France 1 Year Program. France offers an exceptional environment to propel your career with job placements in Michelin Star restaurants, a monthly stipend, and support from the ‘Ministère de l'Intérieur’. Kickstart your hotel industry career with ALZEA INDIA.     </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
            {/* <div className='internship'>
                <div className='container'>
                    <h1>Internship</h1>
                </div>
                <div className='internshipList'>
                    <div className='internshipItem'>
                        <a href='/'>
                        <img src='/images/int1.png' alt='int1.png' className='bgImg' />
                        <div className='overlayContent'>
                            <img src='/images/user.png' alt='user' />
                            <p>Internship in France</p>
                        </div>
                        </a>
                        
                    </div>
                    <div className='internshipItem'>
                    <a href='/'>
                        <img src='/images/int2.png' alt='int2.png' className='bgImg' />
                        <div className='overlayContent'>
                            <img src='/images/users.png' alt='user' />
                            <p>Internship in other countries</p>
                        </div>
                        </a>
                    </div>
                </div>
            </div> */}
            
        
            <div className='testimonial'>
                <div className='container'>
                    <h1> READ TRANSFORMATIVE STORIES OF <br/> GROWTH AND ACHIEVEMENT</h1>
                    {
                        testimonials.length !== 0 &&  <SwipeSlider testimonials={testimonials}/>
                    }
                   
                    <div className='ViewMoreBox'>
                    <Link to="/testimonial" className='primaryButton'>View More</Link>
                    </div>
                   
                </div>
            </div>
            <VideoTestimonial/>
            <div className='pioneersSection'>
                <div className='container'>
                    <div className='pioneersSectionInner'>
                    <div className='row align-center'>
                        <div className='col-md-6 text-center'>
                           <img src='/images/globe.png' alt='globe'/>
                        </div>
                        <div className='col-md-6'>
                            <div className='pioneersSectionContent'>
                                <h1>Pioneers Of France Internships <br/> <span style={{color:'#373737'}}>Students Rate Us #1</span></h1>
                                <p><span>1200</span><br/> Dreams Fulfilled </p>
                                <p><span>11+ Years </span><br/> Of Experience </p>
                                <p><span>Over 200 </span><br/> Michelin Star & Gastronomic Restaurant Partners </p>
                                
                            </div>
                            
                       </div>
                    </div>
                    </div>
                  
                </div>
            </div>

            {
                latestPosts.length !== 0 && <div className='latestPost'>
                <div className='container'>
                    <h1>‘Internship Insights to French Gastronomy : <br/>Our Blogs have got you covered’</h1>
                    <p className='text-center'>Explore the dynamic world of internships, where we unveil the latest trends and possibilities in the industry. Whether you seek answers, motivation, or reasoning to shape your career, our engaging blogs have it all. Take a deeper dive into the realm of culinary excellence and gain valuable insights to empower your professional journey. Learn, Grow and Stay updated with ALZEA INDIA!"</p>
                    <div className='row'>
                      {
                        latestPosts.map((item, i)=>{
                          return <div className='col-lg-4'>
                            
                          <div className='latestPostItems'>
                              <div className='latestPostItemsThumb'>
                                  <img src={item.thumbnail} alt='li.jpg' />
                              </div>
                              <div className='latestPostItemsContent'>
                                  <h3>{item.title}</h3>
                                  <div className='post_author'>
                                      <p>{item.auther_name}</p>
                                      <p>{ format (parseISO(item.post_date), 'd MMM Y') }</p>
                                  </div>                                 
                                  <Link to={'#'} className='borderButton'>View More</Link>

                              </div>
                          </div>
                      </div>
                        })
                      }
                      
                    </div>
                    <div className='ViewMoreBox'>
                    <Link to="/blog" className='primaryButton'>Discover More</Link>
                    </div>
                   
                </div>
            </div>
            }
           
             <div className='faq'>
                <div className='container'>
                    <h1>Any Questions you could possibly have, we have tried to answer it all here.</h1>
                    <Accordions data={faqs} />
                    <div className='ViewMoreBox1'>
                    <Link to="/faq" className='primaryButton'>View More</Link>
                    </div>
                </div>
            </div>
            <div className='career-section'>
                <div className='container'>
                    <div className='career-inner'>
                      <h3>Looking to work in a creative space that offers growth and a learning curve?</h3>
                      <Link to="/" className='borderButton'>Join Our Team</Link>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default Home