import React,{useState} from "react";
import Modal from "../models/Modal";
import { FaPlay } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

const VideoTestimonial = () => {
    const [isOpen, setOpen] = useState(false);

  return (
    <div className="video-testimonial">
        <div className="video-title">
           <h1>Want to have your own unique experience?</h1>
        </div>
      
      <div className="video-testimonial-inner">
        <img src={"/images/videoThumb3.png"} alt="main" />
        <button className="playControl" onClick={() => setOpen(true)}>
          <FaPlay size={50} color="rgba(255, 223, 233, 0.5)" />
        </button>
         <div className="dgre1">
           <img src={"/images/re1.png"} alt="main" />
         </div>
         <div className="dgre2">
           <img src={"/images/re2.png"} alt="main" />
         </div>
      </div>

        <Modal isOpen={isOpen} closeModal={() => setOpen(false)} fullWidth={true}>
                <div className='modalHeader'>
                    <h1 className='pl-0 secondaryColor'>Want to have your own unique experience?</h1>
                    <button onClick={() => setOpen(false)}><MdOutlineClose size={25} /></button>
                </div>
                <div className="modal-body">
                <iframe width="100%" height="400" src="https://www.youtube.com/embed/qrzo_vAsE0M?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
        </Modal>
    </div>
  );
};

export default VideoTestimonial;
