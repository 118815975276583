import React, { Fragment, useState, useEffect } from "react";
import { Apis } from "../config";
import { Link } from "react-router-dom";
import MeetOurTeam from "../components/common/MeetOurTeam";
import BrandLogos from "../components/common/BrandLogos";
const About = () => {
  const [isLoading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [aboutContent, setAboutContent] = useState(null);

  const getData = async () => {
    try {
      const res = await Promise.all([
        fetch(Apis.GetTeamMember),
        fetch(Apis.GetAbout),
      ]);
      const data = await Promise.all(res.map((r) => r.json()));
      if (data) {
        if (data[0] && data[0].status) {
          setTeamData(data[0].teamMembers);
        }
        if (data[1] && data[1].status) {
          setAboutContent(data[1].aboutContent);
        }

        setLoading(false);
      }
    } catch (error) {
      throw Error("Promise failed");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Fragment>
      {isLoading ? (
        <div className="fakeLoader">
          <div className="lod-1">
            <div className="lod-2"></div>
          </div>
        </div>
      ) : (
        <div className="innerPageWrapper">
            <div className="page-banner">
                <div className="container">
                <div className="row align-center">
                    <div className="col-md-7">
                      <div className="page-banner-content">
                      <span>OUR WORK</span>
                       <h4>Expand Your Horizons, Shape Your Career, and Embrace Endless Possibilities with ALZEA INDIA. We specialize in providing internship opportunities for hospitality students in France. Our goal is to ensure an end to end seamless experience. </h4>
                      </div>
                    
                    </div>
                    <div className="col-md-5">
                      <div className="page-banner-img">
                         <img src="/images/abtbnrrit.png" alt="img"/>
                       </div>
                    </div>
                  </div>
                </div>
                 
            </div>
          {/* {aboutContent !== null && (
            <div className="innerPageBanner">
              <div className="container">
                <h1>{aboutContent.page_title}</h1>
                <div className="bannerImg">
                  <img
                    src={aboutContent.banner_image}
                    alt="about-banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          )} */}
          <div className="page-content pt-50 pb-0">
         
              <div className="container ">
                <h3 className="title-3">Our Story</h3>
                <p>Priyanka Agrawal, a passionate individual, discovered ALZEA through a pinned advertisement during her French courses at Alliance Française. Excited by the opportunity, she applied for a Business Development and Marketing Internship. Impressed by her MBA degree, knowledge in tourism, and strong French language skills, Christin, the Head of ALZEA France, granted her the opportunity.</p>
                <p>Initially, Priyanka's role involved mentoring French interns in India, providing them with an authentic cultural experience. As she immersed herself in this role, the idea of offering internships to Indian students in France took hold. It was during her tours with the French interns that she often received inquiries from Indians, for similar opportunities in France. This planted the seeds for ALZEA INDIA.</p>
                <p>In 2011, ALZEA INDIA kickstarted its internship service in France by placing 10 IHM Indian Hotel Management students in the culinary capital.. Since then, the organization has grown exponentially, sending over 1,200 students to fulfill their dreams of interning in France. ALZEA INDIA has become a bridge between ambitious Indian students and esteemed internships in France, offering them a chance to gain invaluable experience.</p>
                <p>The ALZEA INDIA story is one of passion, determination, and the belief in empowering individuals through international opportunities. With each success story and transformative experience, ALZEA INDIA continues to pave the way for young talents to pursue their dreams across borders.</p>
                 <div className="inner-page-section">
                 <div className="row align-center">
                <div className="col-md-5">
                     <div className="inner-img-box">
                         <img src="/images/missionvission.png" alt="img"/>
                       </div>
                    </div>
                  <div className="col-md-7">
                    <div className="inner-content-box">
                      <h3>OUR VISION</h3>
                      <p className="mb-30">We aspire to become the leading platform that connects students with meaningful internships in the gastronomic capital of the world.</p>
                      <h3>OUR MISSION</h3>
                      <p className="mb-0">As the one and only  autonomous consultancy in the hospitality  industry, our mission is to see as many young professionals as possible get the best transformative exposure and emerge as a confident individual, all the while being accountable, holding tight on to our integrity, and staying true to our core values.</p>
                      </div>
                  </div>
                </div>
                 </div>
                 <div className="inner-page-section pt-0">
                   <div className="row align-center">
                  <div className="col-md-7">
                    <div className="inner-content-box">
                      <h3>OUR CORE VALUES</h3>
                       <p><strong>Trust</strong> <br/> Honesty, transparency and accountability throughout the process makes us truystworthy.</p>
                       <p><strong>Passion</strong> <br/> Our team at ALZEA INDIA is fueled by willingness to go above and beyond the call of duty, reflecting our passion for our work.</p>
                       <p><strong>Commitment to Students</strong> <br/> We take a limited number of students and train and support them throughout, thus sticking true to our commitment.</p>
                       <p><strong>Innovation</strong> <br/> We closely analyze the hardships our students meet, and develop solutions in the form of complimentary programs, highlighting our dedication to an innovative approach.</p>
                       </div>
                  </div>
                  <div className="col-md-5">
                     <div className="inner-img-box">
                         <img src="/images/corevalue.png" alt="img"/>
                       </div>
                    </div>
               
                   </div>
                </div>
              </div>
          
           <MeetOurTeam teamData={teamData}/>
           <BrandLogos title={'HOTELS WE HAVE SUCCESSFULLY PLACED IN'}/>
          </div>
          <div className='career-section style-2' >
                <div className='container'>
                    <div className='career-inner'>
                      <h3>Wish to intern in the gastronomic capital of the world?</h3>
                      <Link to="/" className='borderButton'>Check out our Programs</Link>
                    </div>
                </div>
            </div>
        </div>
      )}
    </Fragment>
  );
};

export default About;
