import React, { Fragment } from 'react'
import programData from '../../data/ourProgram.json'

const OurProgram = () => {
  return (
    <Fragment>
    <div className='innerPageWrapper'>
    
      <div className='innerPageBanner'>
      <div className='innerDesignEle'>
        <svg width="491" height="196" viewBox="0 0 491 196" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 -49C30.9796 -4.1415 107.674 87.7727 214.613 96.5613C348.288 107.547 410.061 84.8889 485 192" stroke="url(#paint0_linear_58_3809)" stroke-opacity="0.8" stroke-width="13" />
          <defs>
            <linearGradient id="paint0_linear_58_3809" x1="6" y1="-49" x2="492.334" y2="260.517" gradientUnits="userSpaceOnUse">
              <stop stop-color="#D03D6B" />
              <stop offset="0.547917" stop-color="#B0519A" />
              <stop offset="1" stop-color="#5C65AE" />
            </linearGradient>
          </defs>
        </svg>
      </div>
        <div className='container'>
          <h1>Our Program</h1>
          <div className='bannerImg'>
          <iframe title="myFrame" className='map' src="https://www.google.com/maps/d/u/0/embed?mid=1E8ZHz8RJtWM9A3wSkppi72PWbQSPXBY&ehbc=2E312F" width="100%" height="480"></iframe>
         
            {/* <img src='/images/our-program.jpg' alt='about-banner' className='img-fluid' /> */}
          </div>
        </div>
      </div>
      <div className='page-content pt-15 pb-50'>
        <div className='container'>
        {
          programData.map((item, index)=>{
            return  <div className='programList' key={item.id}>
                      <h1>{item.title}</h1>
                   <div className='row'>
                     {
                      item.programs.map((item2, i)=>{
                        return <div className='col-md-4' key={item2.id}>
                          <div className={`programItems ${item2.id === 1 ? 'active' : ''}`}>
                            <a href={`/our-program/${item.slug}/${item2.slug}`}>
                            <img src={item2.image} alt='' />
                             <h3>{item2.title}</h3>
                            </a>
                            
                             </div>
                        </div>
                      })
                     }
                  </div> 
            </div>
          })
        }
        </div>
       
       
      </div>
    </div>
  </Fragment>
  )
}

export default OurProgram