const API_URL = "https://alzeaapi.articloo.com";
// const API_URL = "http://localhost:4000";
const Apis = {
  GetAllPost:`${API_URL}/api/posts`,
  GetSinglePost:`${API_URL}/api/post/`,
  GetLatestPosts:`${API_URL}/api/posts/latest`,
  GetAllFaq:`${API_URL}/api/faq/all`,
  GetLatestFaq:`${API_URL}/api/faq/latest`,
  SearchFaq:`${API_URL}/api/faq/search?q=`,
  GetAllTestimonial:`${API_URL}/api/testimonial/all`,
  GetLatestTestimonial:`${API_URL}/api/testimonial/latest`,
  GetAllVideoTestimonial:`${API_URL}/api/video/testimonial/all`,
  GetTeamMember:`${API_URL}/api/team/all`,
  SendEnquiry:`${API_URL}/api/enquiry-submit`,
  GetFooterWidget:`${API_URL}/api/footer/widget`,
  GetHomeBannerWidget:`${API_URL}/api/home/banner`,
  GetAbout:`${API_URL}/api/about/content`,
  UploadCV:`${API_URL}/api/upload-cv`,
  GetAppointmentSlots:`${API_URL}/api/get-slots/`,
  CreateAppointment:`${API_URL}/api/appointment/create`,
};
export { API_URL, Apis };
