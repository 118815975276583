import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import programJson from '../../data/ourProgram.json'
import UploadCvModal from '../../components/models/UploadCvModal';

const SingleProgram = () => {
    const {parent_slug, slug} = useParams();
    const [isLoading, setLoading] = useState(true);
    const [programData, setProgramData] = useState(null);
    const [isOpen, setOpen] = useState(false);
   
    useEffect(()=>{
        let programPar = programJson.find(el=>el.slug === parent_slug);
        if (!programPar) {
            window.location.href="/404";
          }

        let mainPro = programPar.programs.find(el=>el.slug === slug);
        if (!mainPro) {
            window.location.href="/404";
          }
          setProgramData(mainPro);
        setLoading(false);
    },[parent_slug, slug])

    return (
        <Fragment>
            <div className='innerPageWrapper'>
                <div className='innerPageBanner'>
                    <div className='container'>
                        <div className='bannerImg2'>
                            <img src='/images/sixm.jpg' alt='about-banner' className='img-fluid' />
                        </div>
                    </div>
                </div>
                <div className='page-content pt-15 pb-50'>
                    {
                        isLoading ? <div className="fakeLoader" ><div className="lod-1"><div className="lod-2"></div></div></div> :  <div className='container'>
                      
                        <div className='our-program-content'>
                        <div className='program-title'>
                            <h3>{programData.title}</h3>
                        </div>
                            <h4>Introduction of the program & why this program </h4>
                            <p>Mauritius is a multiethnic society drawn from Indian, African, French & Chinese origin. It is a major
                                tourist destination attracting more and more tourists from all over the world which in turn boosts the
                                hotel management industry. Doing an internship here has a great scope to learn more about the
                                hotel industry. Since the French cuisine and gastronomy is one of the important cuisines of Mauritius,
                                French becomes an important language used in the hotel industry in Mauritius too. Hence it is an
                                added advantage for the student to learn this language.</p>
                            <div className='eligibility'>
                                <h4>Eligibility</h4>
                                <div className='eligibilityItems'>
                                    <ul>
                                        <li><a href='/'><img src='/images/our-program-icon/icon6.png' alt='icon' /><span>Internships In</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon1.png' alt='icon' /><span>Food Production</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon2.png' alt='icon' /><span>Housekeeping</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon3.png' alt='icon' /><span>Front Office</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon4.png' alt='icon' /><span>Sales & Marketing</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon5.png' alt='icon' /><span>Accounting</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='featureBox'>
                                <h4>Features of the program </h4>
                                <ul>
                                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis.</li>
                                    <li>enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                                </ul>
                            </div>
                            <div className='eligibility'>
                                <h4>Departments  </h4>
                                <div className='eligibilityItems'>
                                    <ul>
                                        <li><a href='/'><img src='/images/our-program-icon/icon6.png' alt='icon' /><span>Internships In</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon1.png' alt='icon' /><span>Food Production</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon2.png' alt='icon' /><span>Housekeeping</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon3.png' alt='icon' /><span>Front Office</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon4.png' alt='icon' /><span>Sales & Marketing</span></a></li>
                                        <li><a href='/'><img src='/images/our-program-icon/icon5.png' alt='icon' /><span>Accounting</span></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='featureBox'>
                                <h4>Benefits of the program  </h4>
                                <ul>
                                    <li>Internship search</li>
                                    <li>Administrative follow - up</li>
                                    <li>Insurance and visa assistance</li>
                                    <li>Accommodation assistance</li>
                                    <li>Individualized follow - up & support during the internship</li>
                                    <li>French courses on Skype</li>

                                </ul>
                            </div>

                            <div className='processList'>
                                <h4>Process</h4>
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='stepBox'>
                                                <div className='stepLabel'>
                                                    <p>Stage 1</p>
                                                </div>
                                                <div className='stepIconContent'>
                                                   <img src='/images/our-program-icon/st1.png' alt='icon' />
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='stepBox'>
                                                <div className='stepLabel'>
                                                    <p>Stage 2</p>
                                                </div>
                                                <div className='stepIconContent'>
                                                   <img src='/images/our-program-icon/st2.png' alt='icon' />
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='stepBox'>
                                                <div className='stepLabel'>
                                                    <p>Stage 3</p>
                                                </div>
                                                <div className='stepIconContent'>
                                                   <img src='/images/our-program-icon/st3.png' alt='icon' />
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='stepBox'>
                                                <div className='stepLabel'>
                                                    <p>Stage 4</p>
                                                </div>
                                                <div className='stepIconContent'>
                                                   <img src='/images/our-program-icon/st4.png' alt='icon' />
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='uploadCv'>
                                <button onClick={()=>setOpen(true)}>Upload Your CV</button>
                            </div>
                            <div className='hotelsList'>
                                <h4>Our Hotals </h4>
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='HotelBox'>
                                                <div className='hotelBoxImage'>
                                                <img src='/images/our-program-icon/ht1.png' alt='icon' />
                                                </div>
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                        <div className='HotelBox'>
                                                <div className='hotelBoxImage'>
                                                <img src='/images/our-program-icon/ht1.png' alt='icon' />
                                                </div>
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                        <div className='HotelBox'>
                                                <div className='hotelBoxImage'>
                                                <img src='/images/our-program-icon/ht1.png' alt='icon' />
                                                </div>
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                        <div className='HotelBox'>
                                                <div className='hotelBoxImage'>
                                                <img src='/images/our-program-icon/ht1.png' alt='icon' />
                                                </div>
                                                   <p>Recaption of internship request by ZEA India & student selection</p>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    }
                   

                </div>
            </div>

           <UploadCvModal isOpen={isOpen} setOpen={setOpen}/>
        </Fragment>
    )
}

export default SingleProgram