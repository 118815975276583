import React, {useState} from 'react'
import Modal from './Modal'
import { MdOutlineClose } from "react-icons/md";
import { FiSend, FiPhoneCall } from "react-icons/fi";
import { Apis } from '../../config';
import swal from 'sweetalert';
const UploadCvModal = ({ isOpen, setOpen }) => {
   const [cvFile, setFile] = useState('');
   const [isLoading, setLoading] = useState(false);
   const [error, setError] = useState('');

    const isValidFileUploaded=(file)=>{
        console.log(file)
        if(file === ''){
              const status = {
                status:false,
                error:'Please select your file !'
            }
            return status;
        }else{
            const validExtensions = ['docx','pdf']
            const fileExtension = file.type.split('/')[1]
             if(validExtensions.includes(fileExtension)){
                const status = {
                    status:true,
                    error:''
                }
                return status;
             }else{
                const status = {
                    status:false,
                    error:'Invalid file format !'
                }
                return status;
             }
        }
       
      }

   const fileChange = e => {
    setError('');
    if(e.target.files.length < 1){
      return;
    }
    const file = e.target.files[0];
    setFile(file);
   
}


const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = isValidFileUploaded(cvFile);
    if(isValid.status){
        setLoading(true)
        let formData = new FormData()
        formData.append('user_cv', cvFile)
        const response = await fetch(Apis.UploadCV, {
          method: 'POST',
          body: formData,
        })
        if (response) {
            if(response.statusText === 'OK'){
                swal({
                    title: "Good job!",
                    text: 'CV uploaded successfully !',
                    icon: "success",
                    button: "OK",
                  });
                setLoading(false);
                setFile('');
                setOpen(false);

            }
          
        }
    }else{
        setError(isValid.error)
    }
    
  }

    return (
        <Modal isOpen={isOpen} closeModal={() => setOpen(false)} maxWidth={450}>
            <div className='modalHeader'>
                <h1 className='pl-0 secondaryColor'>Upload Your CV </h1>
                <button onClick={() => setOpen(false)}><MdOutlineClose size={25} /></button>
            </div>
            <div className='modalContent2 popup-form'>
                        <form onSubmit={handleSubmit} className='form2' encType='multipart/form-data'>
                            <div className='form-group'>
                                <label>Select your file</label>
                                <input type='file' className='form-control2' 
                                 onChange={fileChange}
                                />
                                {error !== '' && <span className='error-help'>{error}</span>}
                                <small className='noticeMsg'><strong>Note : </strong> Only pdf and doc file allowed ! </small>
                            </div>
                            <div className='form-group'>
                                <button 
                                type='submit' 
                                className='primaryButton2 fullWidth'>
                                    
                                   {!isLoading ? <><FiSend fontSize={20} color={'#fff'} />  Send </> : 'Please wait..'} 
                                </button>
                            </div>
                        </form>
                    
                <div className='mobileShowCase'>
                    <FiPhoneCall size={25} color='#C62154' />
                    <p>+91 9561101102</p>
                </div>
            </div>

        </Modal>
    )
}

export default UploadCvModal